import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_vilkencykel/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				motor_type
				average_range_km
				number_of_gears
				battery_capacity
				frame_type
			}
			fields {
				brand_url
				full_name
				full_name_url
				model_url
			}
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "cyklar"
    }}>{`Cyklar`}</h1>
    <p>{`Vi har försökt samla på oss Sveriges störsa databas med elcyklar för att
du som kund enklare ska kunna hitta den cykeln som passar dig. Använd filterfunktionen för att enklare hitta en cykel som passar dig och dina behov. Nedan listar vi mängder med cyklar från flera Svenska butiker.`}</p>
    <p>{`På denna sida har vi samlat alla olika kategorier av elcykler för att du ska kunna välja och vraka. Vet du redan med dig om vilken typ av cykel du vill ha kan du kika in på någon av våra undersidor. Dessa innhåller även information om de olika cykeltyperna vilket kan vara nyttigt att läsa om du ännu inte vet vilken typ av cykel du behöver.`}</p>
    <CategoriesLinks mdxType="CategoriesLinks"></CategoriesLinks>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      